


























































































































































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '../../components/EditorButtonsHelpView.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import { InviteLinkApproveType } from '@/includes/logic/InviteLinks/types';

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import ActivationActionData
  from "piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { ButtonTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'
import { ButtonBuildersGroups } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { mapActions } from 'vuex'

import { Component, Mixins } from 'vue-property-decorator'
import { UserActivationActionType } from "@/includes/types/PmConfig.types";

@Component({
  data() {
    return {
      EditorButtonsHelpView,
      InviteLinkApproveType,
      EntityTypes,
      MediaEditorMode,
    }
  },
  components: {
    SaveChannelConfig,
    ConfigField,
    ActivationActionData,
    MultiMessageEditorWithMediaInput,
    ExtendedHelpMessage
  },
  methods: {
    ...mapActions([ 'resolveFlowSelectOption' ])
  }
})
export default class ChannelInviteLinksSettings extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {

  resolveFlowSelectOption!: Promise<Array<SelectOption>>

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionType.Flow ]
    }
  }

  get isManualApprove() {
    return this.$store.state.channelsState.activeChannel.config.approve === InviteLinkApproveType.Manual
  }

  get isAutoApprove() {
    return this.$store.state.channelsState.activeChannel.config.approve === InviteLinkApproveType.Auto
  }

  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType)
      .filter(type => type !== InviteLinkApproveType.Disabled)
      .map((type) => {
        return {
          label: this.$t(`link_approve_type_${ type.toLowerCase() }`).toString(),
          value: type
        }
      })
  }

  get autoApproveButtons() {
    const message = this.$store.state.channelsState.activeChannel.config.auto_accepted_user_message;

    if (message && message.every(m => !m.buttons?.length || m.buttons.every(r => !r.length))) {
      return [
        new ButtonBuildersGroups(this.$t('editor_buttons_list_group_common').toString(), this.newCaptchaButton),
        new ButtonBuildersGroups(this.$t('editor_buttons_list_group_inline').toString(),this.newPmButtons),
      ]
    }

    if (this.hasCaptchaButton) {
      return this.newCaptchaButton
    } else {
      return this.newPmButtons
    }
  }

  get hasCaptchaButton() {
    return this.$store.state.channelsState.activeChannel!.config.auto_accepted_user_message
      .some(m => m.buttons && m.buttons.length && m.buttons.some(r => r.length && r.some(b => b.type === ButtonTypes.Text)))
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }
}
